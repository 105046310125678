<template>
  <div>
    <div class="grid">
      <input
        id="top"
        type="text"
        :disabled="!editPage"
        v-model="trialName"
        @change="onChange({ type: 'name', val: trialName })"
      />
      <input
        v-if="!editPage"
        id="bottom"
        type="text"
        disabled
        :value="startDate"
      />
      <input
        v-else
        id="bottom"
        type="date"
        v-model="dates[0]"
        @change="onChange({ type: 'start_date', val: dates[0] })"
      />
      <div id="right">
        <label for="endDate">Optional:</label>
        <input
          v-if="!editPage"
          id="endDate"
          type="text"
          :value="endDate"
          placeholder="To be decided"
          disabled
        />
        <input
          v-else
          id="endDate"
          type="date"
          v-model="dates[1]"
          @change="onChange({ type: 'end_date', val: dates[1] })"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Part1Summary",
  data() {
    return {
      dates: null,
      trialName: null,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      default: "",
    },
    editPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(changed) {
      this.$emit("customChange", changed);
    },
  },
  created() {
    if (this.editPage) {
      this.dates = [
        this.startDate.slice(0, 4) +
          "-" +
          this.startDate.slice(4, 6) +
          "-" +
          this.startDate.slice(6, 8),
        this.endDate.slice(0, 4) +
          "-" +
          this.endDate.slice(4, 6) +
          "-" +
          this.endDate.slice(6, 8),
      ];
    } else {
      this.dates = [this.startDate, this.endDate];
    }
    this.trialName = this.name;
  },
};
</script>
<style>
label {
  color: grey;
  font-family: dyson-futura-book;
}
div.grid {
  height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
#endDate {
  margin: 0px 0px -10px 0px;
}
#top {
  margin: 10px 0px 0px 0px;
  grid-column: 1;
  grid-row: 1;
}
#bottom {
  grid-column: 1;
  grid-row: 0;
}
#right {
  grid-column: 0;
  grid-row: 0;
}
input {
  margin: 20px 0px 0px 0px;
  padding: 10px;
  width: 330px;
  height: 48px;
  font-size: 16px;
  color: grey;
  font-family: dyson-futura-book;
  background-color: white;
  border-style: solid;
  border-width: thin;
  border-color: grey;
}
</style>
