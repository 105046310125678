<template>
  <PopUp
    :showProp="showWarning"
    type="warning"
    @custom-change="closePopUp"
    :isLoading="false"
  />
  <router-view />
</template>

<script>
import PopUp from "./components/PopUp.vue";
import CognitoAuth from "./services/cognito.js";
import store from "./store";

export default {
  name: "App",
  data() {
    return {
      events: ["keypress", "click", "mousedown", "mousemove", "scroll"],
      authenticator: new CognitoAuth(),
      warningTimer: null,
      logoutTimer: null,
      showWarning: false,
    };
  },
  components: {
    PopUp,
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimers);
    }, this);
    this.setTimers();
    window.addEventListener("beforeunload", (event) => {
      this.$store.dispatch("updateDevices");
      this.$store.dispatch("updateTrials");
      event.preventDefault();
      event.returnValue = "";
    });
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(this.warningMessage, 29 * 60 * 1000); // 29 minutes
      this.logoutTimer = setTimeout(this.logout, 30 * 60 * 1000); // 30 minutes
    },
    warningMessage() {
      if (store.state.AccessToken != null) {
        this.showWarning = true;
      }
    },
    logout() {
      this.resetTimers();
      if (store.state.AccessToken != null) {
        this.showWarning = false;
        this.$router.push({
          name: "Login Page",
          query: { logout: true },
          replace: true,
        });
      }
    },
    closePopUp() {
      this.showWarning = false;
      this.resetTimers;
    },
    resetTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
  },
};
</script>

<style>
/* import fonts */
@font-face {
  font-family: "dyson-futura-medium";
  src: url(./assets/DysonFutura-Medium.otf) format("OpenType");
}
@font-face {
  font-family: "dyson-futura-book";
  src: url(./assets/DysonFutura-Book.otf) format("OpenType");
}
@font-face {
  font-family: "dyson-futura-heavy";
  src: url(./assets/DysonFutura-Heavy.otf) format("OpenType");
}

/* font styling */
#app {
  font-family: dyson-futura-book, Arial, sans-serif;
}
p {
  font-size: 14px;
  max-width: 800px;
}
h1 {
  font-size: 60px;
  font-family: dyson-futura-heavy;
  margin: -20px 0px 0px;
}
h2 {
  font-size: 35px;
  font-family: dyson-futura-heavy;
  margin-bottom: -5px;
}
a {
  color: #522a86;
  font-family: dyson-futura-medium;
  text-decoration: none;
}
/* page layout */
div.page-body {
  margin-left: 40px;
}

/* Nav Bar Styling */
#nav {
  padding: 30px;
}
.nav-line-devices,
.nav-line-trials {
  position: absolute;
  top: 50px;
  border-top: 2px solid #999999;
}
.nav-line-devices {
  width: 54px;
  left: 48px;
}
.nav-line-trials {
  width: 35px;
  left: 123px;
}
/* Nav Bar - space between links */
div.navLink,
#logoutLink {
  display: inline-block;
  padding: 20px;
  margin: -20px -10px 0px;
}
/* Nav Bar - active Vs Inactive colours */
#nav a {
  color: #999999;
}
#nav a.router-link-exact-active {
  color: black;
  font-family: dyson-futura-medium;
}

/* button*/
button.button {
  font-family: dyson-futura-book;
  border: none;
  color: white;
  padding: 15px 50px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}

button.primary-button {
  background-color: #522a86;
}
button.secondary-button {
  background-color: #000000;
}
</style>
