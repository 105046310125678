<template>
  <div class="btn-container">
    <img
      class="download-button"
      src="../assets/download.png"
      alt="Download Icon"
      style="width: 42px; height: 42px"
    />
    <p class="download-button-text">Download</p>
  </div>
</template>

<script>
export default {
  name: "DownloadRecords",
};
</script>

<style scoped>
.btn-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  margin: -20px 0px 0px;
}
</style>
