<template>
  <div>
    <div id="nav">
      <div class="navLink">
        <router-link to="/devices">Devices</router-link>
      </div>
      <div class="navLink"><router-link to="/trials">Trials</router-link></div>
      <div class="navLink">
        <router-link
          :to="{ name: 'Login Page', query: { logout: true } }"
          replace
          >Logout</router-link
        >
      </div>
    </div>
    <router-view />
    <!-- nav bar underline -->
    <hr class="nav-line-devices" />
    <div class="page-body">
      <h1 id="title">{{ deviceSerial }}</h1>

      <router-link
        :to="{
          path: '/download',
          query: { serialOrId: deviceSerial, type: 'devices' },
        }"
      >
        <DownloadRecords id="downloadBtn" />
      </router-link>
      
      <div id="leftColumn">
        <span v-if="device != null">
          {{ 
            device["encrypted"] 
              ? "🔐 The location data from this device is encrypted"
              : ""
          }}
        </span>
        <div v-if="!device">
          <div class="loading">
            <h2 class="summary">Summary</h2>
            <img
              id="spinner"
              class="loading"
              src="@/assets/Spinner-Animated.gif"
            />
          </div>
          <div class="spinner" id="bottom">
            <h2 class="summary">Trials</h2>
            <img
              id="spinner"
              class="loading"
              src="@/assets/Spinner-Animated.gif"
            />
          </div>
        </div>
        <div v-else>
          <h2>Summary</h2>
          <DeviceSummaryTable :deviceData="device" />
          <h2>Trials</h2>
          <div id="DDTrials">
            <DDTrials :device="device" />
          </div>
        </div>
      </div>

      <div id="rightColumn">
        <div v-if="device">
<!--          <h2>Activity</h2>-->
<!--          <div class="cal">-->
<!--            <Calendar :attributes="attributes" />-->
<!--          </div>-->
<!--          <h2>Timeline</h2>-->
<!--          <div>-->
<!--            <DeviceHistoryTable :deviceData="device" />-->
<!--          </div>-->
        </div>
        <div v-else>
          <div class="loading">
            <h2>Activity</h2>
            <div class="cal">
              <Calendar :attributes="attributes" />
            </div>
          </div>
          <div class="spinner">
            <h2 class="summary">Timeline</h2>
            <img
              id="spinner"
              class="loading"
              src="@/assets/Spinner-Animated.gif"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "@/services/AxiosService.js";
import DownloadRecords from "@/components/DownloadRecords.vue";
import DDTrials from "@/components/DeviceDetail/DDTrials.vue";
import DeviceSummaryTable from "@/components/DeviceDetail/DeviceSummaryTable.vue";
// import DeviceHistoryTable from "@/components/DeviceDetail/DeviceHistoryTable.vue";
import { Calendar } from "v-calendar";
import "v-calendar/dist/style.css";

export default {
  name: "DeviceDetail",
  data() {
    return {
      device: null,
      attributes: [
        {
          highlight: {
            color: "purple",
            fillMode: "light",
          },
          dates: [],
        },
      ],
    };
  },
  components: {
    DDTrials,
    DownloadRecords,
    DeviceSummaryTable,
    Calendar,
    // DeviceHistoryTable,
  },
  computed: {
    deviceSerial() {
      return this.$route.query.device;
    },
  },
  async created() {
    try {
      var response = await AxiosService.getDevice(this.deviceSerial);

      // Dummy list to avoid Vue error - remove line below when backend of timeline is implemented
      response.data["timeline"] = [];
      response.data["activity"] = [];

      response.data["registered"] = response.data.deviceRemoved
        ? "Unregistered"
        : "Registered";
      this.device = response.data;
      for (let timestamp of this.device.activity) {
        var newDate = this.createDate(timestamp);
        this.attributes[0].dates.push(newDate);
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    createDate(dateString) {
      let newDate = new Date(
        parseInt(dateString.slice(0, 4)),
        parseInt(dateString.slice(4, 6)) - 1,
        parseInt(dateString.slice(6, 8))
      );
      return newDate;
    },
    formatDate(dateString) {
      return (
        dateString.slice(6, 8) +
        "-" +
        dateString.slice(4, 6) +
        "-" +
        dateString.slice(0, 4)
      );
    },
  },
};
</script>

<style scoped>
.page-body {
  display: grid;
  justify-items: start;
  align-items: start;
  grid-template-columns: repeat(2, 500px);
  grid-template-rows: auto 10000px;
}
#bottom {
  margin: 120px 0px 0px 0px;
}
#title {
  grid-column-start: 1;
  grid-row-start: 1;
}
#downloadBtn {
  grid-column-start: 2;
  grid-row-start: 1;
}
#DDTrials {
  margin-top: -10px;
}
#leftColumn {
  grid-column-start: 1;
  grid-row-start: 2;
}
div.cal {
  margin: 10px 0px 0px 0px;
}
h2.summary {
  float: left;
}
#rightColumn {
  grid-column-start: 2;
  grid-row-start: 2;
}
div.spinner {
  display: inline-block;
}
div.loading {
  display: block;
}
img.loading {
  width: 50px;
  height: 25x;
  float: right;
  padding: 40px 0px 0px 0px;
}
</style>
