<template>
  <!-- table heading -->
  <div class="table-heading table-row">
    <hr class="solid" />
    <div
      v-for="tableHeading in tableHeadings"
      v-bind:key="tableHeading.heading"
      class="table-value-box"
    >
      {{ tableHeading.heading }}
    </div>
    <hr class="solid" />
  </div>
  <!-- table rows -->
  <div class="table-row">
    <div class="table-value-box">{{ formatDate(trial.start_date) }}</div>
    <div class="table-value-box">{{ formatDate(trial.end_date) }}</div>
    <div class="table-value-box">{{ trial.devices.length }}</div>
    <hr class="solid" />
  </div>
</template>

<script>
export default {
  name: "TrialSummaryTable",
  props: {
    trial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeadings: [
        { heading: "Start Date" },
        { heading: "End Date" },
        { heading: "Number of Devices" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return date
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : "To be decided";
    },
  },
};
</script>

<style scoped>
div.table-row {
  width: 384px;
}

/* heading styling */
div.table-heading {
  background: rgb(230, 227, 227);
  font-family: dyson-futura-medium;
}

/* row spacing and layout */
div.table-value-box {
  display: inline-block;
  padding: 10px 0;
  width: 120px;
  font-size: 14px;
  text-align: center;
}
</style>
