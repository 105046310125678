<template>
  <div class="overlay" v-show="show">
    <div class="popup">
      <h2 :class="type">{{ headers[type] }}</h2>
      
      <div v-show="this.type == 'warning'">
        <button class="close" v-on:click="closePopUp">&times;</button>
        <div>
          <br />
          Your session will time out in 60s
        </div>
      </div>

      <div v-show="this.type == 'otp'">
        <div class="full">
          <br />
          <input
            class="otp"
            type="text"
            placeholder="Two Factor Authentication"
            v-model="otp"
            required
          />
          <p :v-show="error != null" class="error">{{ error }}</p>
          <LoadingButton
            text="Sign in"
            :isLoading="isLoading"
            :onClick="emitOTP"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingButton from "./LoadingButton.vue";
export default {
  name: "PopUp",
  data() {
    return {
      headers: {
        otp: "Please enter your 2FA:",
        warning: "Warning!",
      },
      show: false,
      otp: "",
      error: null,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    showProp: {
      type: Boolean,
      required: true,
    },
    attempt: {
      type: Number,
      default: 0,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LoadingButton,
  },
  methods: {
    closePopUp() {
      this.$emit("customChange");
    },
    emitOTP() {
      this.$emit("customChange", this.otp);
    },
  },
  updated() {
    this.show = this.showProp;
    if (this.attempt > 1) {
      this.error = "Incorrect 2FA, please try again";
    }
  },
};
</script>

<style scoped>
.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  opacity: 1;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
}
.popup h2 {
  margin-top: 0;
  color: #333;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.full {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
input {
  width: fit-content;
  text-align: center;
}
h2.otp {
  text-align: center;
}

p.error {
  font-family: dyson-futura-medium, Arial, sans-serif;
  color: red;
  text-align: center;
  font-size: 13px;
  opacity: 0.5;
}
</style>
