<template>
  <div class="btn-container">
    <img
      class="bin-button"
      src="../assets/bin.svg"
      alt="bin Icon"
      style="width: 42px; height: 42px"
    />
    <p id="delete-btn-text">Delete</p>
  </div>
</template>

<script>
export default {
  name: "binBtn",
};
</script>

<style scoped>
.btn-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  margin: -20px 0px 0px;
}
#delete-btn-text {
  color: black;
}
</style>
