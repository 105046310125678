<template>
  <div v-if="trial" class="page-body">
    <!-- page header -->
    <div class="container">
      <h1 id="title">{{ trial.trial_name }}</h1>
      <router-link
        :to="{
          path: '/download',
          query: { serialOrId: trial.trial_id, type: 'trials' },
        }"
      >
        <DownloadRecords id="DownloadBtn" />
      </router-link>
      <router-link
        :to="{
          path: '/editTrial',
          query: { trialDevices: devices, trialName: trial.trial_name },
        }"
        ><EditBtn id="EditBtn"
      /></router-link>
      <router-link
        :to="{
          path: '/deleteTrial',
          query: { trialName: trial.name, trialId: trial.trial_id },
        }"
        ><DeleteBtn id="DeleteBtn"
      /></router-link>
    </div>
    <p id="trialId">Trial id: {{ trial.trial_id }}</p>
    <!-- page body -->
    <h2>Summary</h2>
    <TrialSummaryTable :trial="trial" />
    <h2>Devices</h2>
    <TrialDevicesTable :devices="trial.devices" />
    <button
      class="button secondary-button"
      @click="this.$router.replace('/trials')"
    >
      Back
    </button>
  </div>
  <div v-else class="page-body">
    <!-- page header -->
    <div class="container">
      <h1 id="title">{{ this.$route.query.trialName }}</h1>
      <router-link
        :to="{
          path: '/download',
          query: { serialOrId: this.$route.query.trialId, type: 'trials' },
        }"
      >
        <DownloadRecords id="DownloadBtn" />
      </router-link>
      <router-link
        :to="{
          path: '/editTrial',
          query: { trialDevices: null, trialName: this.$route.query.trialName },
        }"
        ><EditBtn id="EditBtn"
      /></router-link>
      <router-link
        :to="{
          path: '/deleteTrial',
          query: {
            trialName: this.$route.query.trialName,
            trialId: this.$route.query.trialId,
          },
        }"
        ><DeleteBtn id="DeleteBtn"
      /></router-link>
    </div>
    <p id="trialId">Trial id: {{ this.$route.query.trialId }}</p>
    <!-- page body -->
    <div class="container" id="loading">
      <div class="spinner">
        <h2 class="summary">Summary</h2>
        <img id="spinner" class="loading" src="@/assets/Spinner-Animated.gif" />
      </div>
      <div class="spinner" id="bottom">
        <h2 class="summary">Devices</h2>
        <img id="spinner" class="loading" src="@/assets/Spinner-Animated.gif" />
      </div>
    </div>
    <button
      class="button secondary-button"
      @click="this.$router.replace('/trials')"
    >
      Back
    </button>
  </div>
</template>

<script>
import AxiosService from "@/services/AxiosService.js";
import DownloadRecords from "@/components/DownloadRecords.vue";
import EditBtn from "@/components/EditBtn";
import DeleteBtn from "@/components/DeleteBtn";
import TrialDevicesTable from "@/components/TrialDetail/TrialDevicesTable.vue";
import TrialSummaryTable from "@/components/TrialDetail/TrialSummaryTable.vue";

export default {
  name: "TrialDetail",
  data() {
    return {
      trial: null,
    };
  },
  computed: {
    devices() {
      var deviceString = "";
      for (let device of this.trial.devices) {
        deviceString = deviceString.concat(device.serial, ",");
      }
      return deviceString;
    },
  },
  components: {
    DownloadRecords,
    EditBtn,
    DeleteBtn,
    TrialDevicesTable,
    TrialSummaryTable,
  },
  async created() {
    try {
      var trialResp = await AxiosService.getTrial(this.$route.query.trialId);
      this.trial = trialResp.data;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
#title {
  grid-column-start: 1;
}
#DownloadBtn {
  grid-column-start: 2;
}
#EditBtn {
  grid-column-start: 3;
}
#DeleteBtn {
  grid-column-start: 4;
}
#loading {
  margin: -15px 0px 0px 0px;
}
.container {
  display: grid;
  justify-items: start;
  align-items: start;
  grid-template-columns: 500px repeat(3, 100px);
}
#trialId {
  margin-top: -5px;
}

div.page-body {
  margin-left: 50px;
  padding-top: 2%;
}
div.spinner {
  display: inline-block;
}
div.loading {
  display: block;
}
img.loading {
  width: 50px;
  height: 25x;
  float: right;
  padding: 40px 0px 0px 0px;
}
#bottom {
  margin: 110px 0px 20px 0px;
}
h2.summary {
  float: left;
}
</style>
