<template>
  <div class="page-body">
    <h1>Create a trial</h1>
    <p>
      A trial is a subset of your data created through filtering by dates and
      devices.
    </p>
    <!-- View 1 : Form Part 1  -->
    <div v-if="onPartOne">
      <h2>Part 1: Trial name and dates</h2>
      <p>
        Set up your trial name, start date and end date. You may decide on the
        end date of your trial later on.
      </p>
      <form>
        <!-- Input field 1 -->
        <div class="multi-label">
          <div v-if="incompleteForm" class="required">*Required</div>
        </div>
        <input
          type="text"
          id="trialName"
          v-model="trialName"
          placeholder="Trial Name"
        /><br />
        <!-- Input field 2 -->
        <div class="date-box">
          <div class="multi-label">
            <label for="StartDate">Start date</label><br />
            <div v-if="incompleteForm" class="required">*Required</div>
          </div>
          <input type="date" id="StartDate" v-model="startDate" />
        </div>
        <!-- Input field 3 -->
        <div class="date-box">
          <div class="multi-label">
            <label for="EndDate">End date </label>
            <div class="optional">Optional</div>
          </div>
          <input type="date" id="EndDate" v-model="endDate" />
        </div>
      </form>
      <div class="buttons">
        <LoadingButton
          text="Back"
          classStyle="secondary-button"
          :onClick="goBack"
        />
        <LoadingButton text="Next" :onClick="onSelectDevices" />
        <div class="errors">
          <p class="errors" v-for="error in errors" :key="error">
            {{ error }}
          </p>
        </div>
      </div>
    </div>

    <!-- View 2: Form Part 2  -->
    <div v-if="!onPartOne">
      <!-- Part 1 pre-filled information  -->
      <h2>Part 1 : Trial name and dates</h2>
      <p>
        <Part1Summary
          :name="this.trialName"
          :startDate="startDate"
          :endDate="endDate"
        />
      </p>
      <!-- Part 2 Fields -->
      <h2>Part 2 : Select devices</h2>
      <p>
        <DeviceSelection
          :start_date="startDate"
          :end_date="endDate"
          @custom-change="changeDevices"
        />
      </p>
      <LoadingButton
        text="Back"
        classStyle="secondary-button"
        :onClick="goBackToPartOne"
      />
      <LoadingButton
        text="Create trial"
        :onClick="createNewTrial"
        :isDisabled="!validSubmit"
        :isLoading="isLoading"
      />
    </div>
    {{ newTrial }}
  </div>
</template>

<script>
import Part1Summary from "@/components/CreateTrial/Part1Summary.vue";
import DeviceSelection from "@/components/CreateTrial/DeviceSelection.vue";
import AxiosService from "@/services/AxiosService.js";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
  name: "CreateTrial",
  data() {
    return {
      trialName: null,
      startDate: null,
      endDate: null,

      // flags
      incompleteForm: false,
      onPartOne: true,

      selectedDevices: [],
      errors: [],
      validSubmit: false,

      isLoading: false,
      newTrial: null,
    };
  },
  components: {
    Part1Summary,
    LoadingButton,
    DeviceSelection,
  },
  methods: {
    changeDevices(event) {
      this.selectedDevices = event;
      this.validSubmit = this.selectedDevices.length != 0;
    },
    onSelectDevices() {
      // if required form fields are not filled in
      if (!this.trialName || !this.startDate) {
        this.incompleteForm = true;
      } else if (this.validateData()) {
        // remove "*required" notices
        this.incompleteForm = false;
        // Move on to part 2.
        this.onPartOne = false;
      } else {
        this.incompleteForm = false;
      }
    },
    goBack() {
      this.$router.replace("/trials");
    },
    goBackToPartOne() {
      this.onPartOne = true;
    },
    createNewTrial() {
      this.isLoading = true;

      var deviceList = "";
      for (let item of this.selectedDevices) {
        deviceList += item + ",";
      }

      if (this.validateData()) {
        let trial = {
          trial_name: this.trialName,
          start_date: this.startDate,
          end_date: this.endDate,
          device_list: deviceList.slice(0, -1),
        };
        AxiosService.createNewTrial(trial)
          .then((response) => {
            this.newTrial = response.data;
            console.log(
              "New Trial Created. Trial ID: ",
              response.data.trial_id
            );
            this.$store.dispatch("updateTrials");
            this.$router.replace("/trials");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 400) {
              console.log(
                "The following devices aren't available for the date range selected: ",
                error.response.data["Devices not in trial"]
              );
            } else {
              console.log("Error creating trial");
            }
          });
      }
    },
    validateData() {
      this.errors = [];
      if (this.$store.state.trials) {
        if (this.trialName in this.$store.state.trials) {
          this.errors.push("Test name must be unique.");
        }
      }
      if (this.endDate && this.endDate < this.startDate) {
        this.errors.push("End date cannot be after start date.");
      }
      return this.errors.length == 0;
    },
  },
};
</script>

<style scoped>
button:disabled {
  background: #bbacc3;
}
input {
  margin: 0px 12px 0px 0px;
  padding: 10px;
  width: 330px;
  height: 48px;
  font-size: 16px;
  color: grey;
  font-family: dyson-futura-book;
  background-color: white;
  border-style: solid;
  border-width: thin;
  border-color: grey;
}
label {
  font-size: 14px;
  margin: 20px 0px 0px 0px;
  font-family: dyson-futura-book, Arial, sans-serif;
}
.optional {
  color: #999999;
  font-size: 14px;
  font-style: italic;
}
.required {
  font-size: 14px;
  color: red;
}
div {
  display: inline-block;
}
div.buttons {
  margin: 45px 0px 0px 0px;
}
.multi-label {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 350px;
}
h2 {
  margin: 0px;
}
p.errors {
  padding: 0px;
  margin: 0px;
}
div.errors {
  margin: 0px 0px 0px 20px;
  padding: 5px 0px 0px 0px;
  font-family: dyson-futura-book, Arial, sans-serif;
  color: red;
  text-align: center;
}
div.page-body {
  margin-left: 50px;
  padding-top: 2%;
}
</style>
