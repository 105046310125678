<template>
<div class="overlay" v-show="show">
    <div class="popup">
        <h2 :class="type">Select device(s) and enter your Public Key</h2>
        <button class="close" v-on:click="closePopUp">&times;</button>
        <p :v-show="error != null" class="error">{{ error }}</p>
        <div class="full">
            <div class="public-key-devices">
                <div class="public-key-device-row" v-for="device in devices" v-bind:key="device.serial">
                    <label class="public-key-device-row-box">
                        <input :id="device.serial" :value="device.serial" name="device" type="checkbox" v-model="selectedDevices" />
                        <span class="checkmark"><img id="tick" src="@/assets/tick.svg" alt="tick"/></span>
                    </label>
                    {{ device.serial }}
                </div>
            </div>
            <input class="public-key" type="text" placeholder="Public Key" v-model="publicKey" required/>
            <p :v-show="error != null" class="error">{{ error }}</p>
            <LoadingButton
                text="Upload Public Key"
                :isLoading="isUploading"
                :isDisabled="isButtonDisabled"
                :onClick="uploadPublicKey"
            />
        </div>
    </div>
</div>
</template>
<script>
import LoadingButton from "./LoadingButton.vue";
import Service from "@/services/AxiosService.js";
export default {
    name: "PublicKeyPopUp",
    data() {
        return { 
            publicKey: "",
            selectedDevices: [],
            error: null,
            isUploading: false 
        }
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    components: {
        LoadingButton,
    },
    computed: {
        devices() {
            return this.$store.state.devices;
        },
        isButtonDisabled() {
            return this.selectedDevices.length === 0 || this.publicKey === "";
        }
    },
    methods: {
        async uploadPublicKey() {
            this.isUploading = true;
            const devices = this.selectedDevices.join(",");
            try {
                await Service.setPublicKey(this.publicKey, devices);
                this.closePopUp();
            } catch(error) {
                console.error(`Error uploading public key - ${error}`);
                this.error = "Error uploading public key, please try again";
            }
        },
        closePopUp() {
            this.$emit("customChange");
        },
    }
}
</script>
<style scoped>
.overlay {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  opacity: 1;
}
.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
}
.popup h2 {
  padding-top: 10px;
  color: #333;
  text-align: center;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.full {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.public-key-device-row {
  width: 400px;
  position: relative;
  margin: auto;
}

.public-key-device-row-box {
  display: inline-block;
  padding: 12px 0;
  width: 50px;
  font-size: 14px;
  text-align: center;
}
.checkmark {
  position: absolute;
  margin: auto;
  height: 15px;
  width: 15px;
  border: 1px solid rgb(0, 0, 0);
}
#tick {
  position: absolute;
  right: 4px;
  top: 4.5px;
  opacity: 0;
  width: 50%;
}
input:checked ~ .checkmark > #tick {
  opacity: 100;
}

.public-key-device-row input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

p.error {
  font-family: dyson-futura-medium, Arial, sans-serif;
  color: red;
  text-align: center;
  font-size: 13px;
  opacity: 0.5;
}

</style>