<template>
  <!-- table heading -->
  <div class="table-heading table-row">
    <hr class="solid" />
    <div
      v-for="tableHeading in tableHeadings"
      v-bind:key="tableHeading.heading"
      class="table-value-box"
    >
      {{ tableHeading.heading }}
    </div>
    <hr class="solid" />
  </div>
  <!-- table rows -->
  <div v-for="trial in trials" v-bind:key="trial.id" class="table-row">
    <div class="table-value-box">
      <router-link
        :to="{
          path: '/trial',
          query: { trialId: trial.trial_id, trialName: trial.trial_name },
        }"
        >{{ trial.trial_name }}</router-link
      >
    </div>
    <div class="table-value-box">{{ formatDate(trial.start_date) }}</div>
    <div class="table-value-box">{{ formatDate(trial.end_date) }}</div>
    <div class="table-value-box">{{ trial.device_count }}</div>
    <hr class="solid" />
  </div>
</template>

<script>
export default {
  name: "TrialsTable",
  data() {
    return {
      tableHeadings: [
        { heading: "Name" },
        { heading: "Start Date" },
        { heading: "End Date" },
        { heading: "Number of Devices" },
      ],
    };
  },
  computed: {
    trials() {
      var trialList = [];
      for (let name in this.$store.state.trials) {
        var trial = this.$store.state.trials[name];
        trialList.push(trial);
      }
      return trialList;
    },
  },
  methods: {
    formatDate(date) {
      return date
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : "Not available";
    },
  },
};
</script>

<style scoped>
div.table-row {
  width: 520px;
}

/* heading styling */
div.table-heading {
  background: rgb(230, 227, 227);
  font-family: dyson-futura-medium;
}

/* row spacing and layout */
div.table-value-box {
  display: inline-block;
  padding: 10px 0;
  width: 120px;
  font-size: 14px;
  text-align: center;
}
</style>
