<template>
  <!-- table heading -->
  <div class="table-heading table-row">
    <hr class="solid" />
    <div
      v-for="tableHeading in tableHeadings"
      v-bind:key="tableHeading.heading"
      class="table-value-box"
    >
      {{ tableHeading.heading }}
    </div>
    <hr class="solid" />
  </div>
  <!-- table rows -->
  <div class="table-row">
    <div class="table-value-box">{{ formatDate(deviceData.lastSeen) }}</div>
    <div class="table-value-box">{{ deviceData.registered }}</div>
    <hr class="solid" />
  </div>
</template>

<script>
export default {
  name: "DeviceSummaryTable",
  props: {
    deviceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeadings: [
        { heading: "Latest upload" },
        { heading: "Registration" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return date != "Not available"
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : date;
    },
  },
};
</script>
<style scoped>
div.table-row {
  width: 256px;
}

/* heading styling */
div.table-heading {
  background: rgb(230, 227, 227);
  font-family: dyson-futura-medium;
}

/* row spacing and layout */
div.table-value-box {
  display: inline-block;
  padding: 10px 0;
  width: 120px;
  font-size: 14px;
  text-align: center;
}
</style>
