<template>
  <div>
    <!-- table heading -->
    <div class="table-heading table-row">
      <hr class="solid" />
      <!-- select all heading -->
      <label class="table-value-box">
        <input
          type="checkbox"
          id="selectAll"
          v-model="allSelected"
          @change="
            selectAll();
            onChange();
          "
        />
        <span class="checkmark"
          ><img id="tick" src="@/assets/tick.svg" alt="tick"
        /></span>
      </label>
      <!-- other headings -->
      <div
        v-for="tableHeading in tableHeadings"
        v-bind:key="tableHeading.heading"
        class="table-value-box"
      >
        {{ tableHeading.heading }}
      </div>
      <hr class="solid" />
    </div>
    <!-- table rows -->
    <div v-for="device in devices" :key="device.serial" class="table-row">
      <!-- Checkbox column content-->
      <label class="table-value-box" id="top">
        <input
          type="checkbox"
          :id="device.serial"
          :value="device.serial"
          v-model="selected"
          @change="
            onChange();
            select();
          "
        />
        <span class="checkmark"
          ><img id="tick" src="@/assets/tick.svg" alt="tick"
        /></span>
      </label>
      <!-- Serial column value-->
      <div class="table-value-box" id="top">
        <router-link :to="'/device?device=' + device.serial">{{
          device.serial
        }}</router-link>
      </div>
      <!-- Overlapping trials column value-->
      <div v-if="device.overlappingTrials" class="table-value-box">
        <span
          class="trialName"
          v-for="trial in device.overlappingTrials"
          v-bind:key="trial"
        >
          <!-- need to add in a router link around the script below <router-link :to="{ path:'/trial', query:{trialName:trial, trialId:trial.id}}"> -->
          {{ trial }} <br />
        </span>
      </div>
      <div v-else class="table-value-box">None</div>
      <!-- row line -->
      <hr class="solid" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DevicesTable",
  data() {
    return {
      tableHeadings: [{ heading: "Serial" }, { heading: "Overlapping trials" }],
      selected: [],
      deviceSerials: [],
      allSelected: false,
    };
  },
  computed: {
    devices() {
      return this.getAvailableDevices();
    },
    trial_start() {
      let dateString = this.formatDate(this.start_date);
      return this.createDate(dateString);
    },
    trial_end() {
      let dateString = this.end_date ? this.formatDate(this.end_date) : null;
      return dateString ? this.createDate(dateString) : null;
    },
  },
  props: {
    start_date: {
      type: String,
      required: true,
    },
    end_date: {
      type: String,
      default: null,
    },
    chosen: {
      type: Array,
      required: false,
      default: null,
    },
  },
  mounted() {
    this.selected = this.chosen ? this.chosen : [];
  },
  updated() {
    var validDevices = this.validateDevices();
    this.$emit("customChange", validDevices);
  },
  methods: {
    onChange() {
      this.selected = this.validateDevices();
      this.$emit("customChange", this.selected);
    },
    selectAll() {
      this.selected = [];

      if (this.allSelected) {
        for (let device in this.devices) {
          this.selected.push(this.devices[device].serial);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    validateDevices() {
      var validDevices = [];
      for (let device of this.selected) {
        if (this.deviceSerials.includes(device)) validDevices.push(device);
      }
      return validDevices;
    },
    getAvailableDevices() {
      this.deviceSerials = [];
      var allDevices = this.$store.state.devices;
      var availableDevices = [];

      for (let device of allDevices) {
        let device_start = this.createDate(device.deviceAdded);
        let device_end = device.deviceRemoved
          ? this.createDate(device.deviceRemoved)
          : null;

        // Device registered before end of trial and de-registered after start of trial/not de-registered
        if (
          (!this.trial_end || this.trial_end > device_start) &&
          (!device_end || device_end > this.trial_start)
        ) {
          this.findOverlappingTrials(device, this.trial_start, this.trial_end);

          // Only add unique devices
          const i = availableDevices.findIndex(e => e.serial === device.serial);
          if (i === -1) {
            availableDevices.push(device);
            this.deviceSerials.push(device.serial);
          }
        }
      }
      return availableDevices;
    },
    findOverlappingTrials(availableDevice, trial_start, trial_end) {
      availableDevice["overlappingTrials"] = null;
      var allTrials = availableDevice.activeTrials.concat(
        availableDevice.futureTrials,
        availableDevice.previousTrials
      );

      // Iterate through all trials associate with a device and then check if they overlap with chosen dates
      for (let trial of allTrials) {
        let beginning = this.createDate(trial.start_date.S);
        let ending = trial.end_date ? this.createDate(trial.end_date.S) : null;

        if (
          (beginning < trial_end || !trial_end) &&
          (ending > trial_start || !ending)
        ) {
          try {
            availableDevice["overlappingTrials"].push(trial.trial_name.S);
          } catch (err) {
            availableDevice["overlappingTrials"] = [trial.trial_name.S];
          }
        }
      }
    },
    createDate(dateString) {
      let newDate = new Date(
        parseInt(dateString.slice(0, 4)),
        parseInt(dateString.slice(4, 6)) - 1,
        parseInt(dateString.slice(6, 8))
      );
      return newDate;
    },
    formatDate(dateString) {
      return (
        dateString.slice(0, 4) +
        dateString.slice(5, 7) +
        dateString.slice(8, 10)
      );
    },
  },
};
</script>

<style scoped>
#top {
  vertical-align: top;
}

/* generic row styling */
div.table-row {
  width: 400px;
  position: relative;
}

/* heading styling */
div.table-heading {
  background: rgb(230, 227, 227);
  font-family: dyson-futura-medium;
}

/* row spacing and layout */
.table-value-box {
  display: inline-block;
  padding: 10px 0;
  width: 120px;
  font-size: 14px;
  text-align: center;
}

.checkmark {
  position: absolute;
  margin: auto;
  height: 15px;
  width: 15px;
  border: 1px solid rgb(0, 0, 0);
}
#tick {
  position: absolute;
  right: 4px;
  top: 4.5px;
  opacity: 0;
  width: 50%;
}
input:checked ~ .checkmark > #tick {
  opacity: 100;
}
input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.trialName {
  margin-left: -4px;
}
</style>
