import axios from "axios";
import store from "../store";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_AWS_BASE_URL + process.env.VUE_APP_AWS_VERSION,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const setTokenFromCookies = () => {
  let header = "Authorization";
  if (apiClient.defaults.headers.common[header] == null) {
    apiClient.defaults.headers.common["Authorization"] = store.state.IdToken;
  }
};

export default {
  getDevices() {
    setTokenFromCookies();
    return apiClient.get("/devices");
  },
  getTrials() {
    setTokenFromCookies();
    return apiClient.get("/trials");
  },
  requestDownload(type, serialOrId) {
    setTokenFromCookies();
    return apiClient.post("/" + type + "/" + serialOrId + "/download");
  },
  checkDownload(type, serialOrId, invocId) {
    setTokenFromCookies();
    let url = "/" + type + "/" + serialOrId + "/download";
    return apiClient.get(url, { params: invocId });
  },
  getTrial(trialId) {
    setTokenFromCookies();
    return apiClient.get("/trials/" + trialId);
  },
  createNewTrial(trial) {
    setTokenFromCookies();
    return apiClient.post("/trials", null, { params: trial });
  },
  deleteTrial(trialId) {
    setTokenFromCookies();
    return apiClient.delete("/trials/" + trialId);
  },
  getDevice(serial) {
    setTokenFromCookies();
    return apiClient.get("/devices/" + serial);
  },
  updateTrial(trial) {
    setTokenFromCookies();
    return apiClient.put("/trials/" + trial.trial_id, null, { params: trial });
  },
  setPublicKey(publicKey, devices) {
    setTokenFromCookies();
    let body = {
      "public_key": publicKey,
      "devices": devices
    }
    return apiClient.post("/encryption_keys", body)
  }
};
