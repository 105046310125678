<template>
  <button
    type="submit"
    :class="['button', 'spinner_container', classStyle]"
    id="submit"
    :disabled="isLoading || isDisabled"
    @click="onClick"
  >
    {{ this.text }}
    <Spinner :loading="isLoading" :size="40" />
  </button>
</template>

<script>
import Spinner from "./Spinner.vue";
export default {
  name: "LoadingButton",
  props: {
    text: {
      type: String,
      default: "Submit",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    classStyle: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  components: {
    Spinner,
  },
};
</script>

<style scoped>
.spinner_container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 20%;
  right: 5%;
}

#submit {
  border: none;
  background-color: #522a86;
  font-size: 16px;
  padding: 20px 80px;
}

#submit.secondary-button {
  background-color: #000000;
}
</style>
