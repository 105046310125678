<template>
  <table>
    <thead>
      <tr >
        <th 
          v-for="tableHeading in tableHeadings" 
          v-bind:key="tableHeading.heading"
          class="table-value-box"
          v-bind:class="[sortBy === tableHeading.heading ? sortDirection : '']"
          @click="sort(tableHeading.heading)"
          scope="col">{{ tableHeading.heading }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="device in sortedDevices" :key="device">
        <td class="table-value-box">
          {{ device["encrypted"] ? "🔐" : ""  }}
          <router-link :to="'/device?device=' + device.serial">
            {{ device.serial }}
          </router-link>
        </td>
        <td class="table-value-box">{{ formatDate(device.lastRecorded) }}</td>
        <td class="table-value-box">{{ formatDate(device.lastSeen) }}</td>
        <td v-if="device.status === 'Active'" class="table-value-box">Yes</td>
        <td v-else class="table-value-box">No</td>
        <td class="table-value-box">{{ device.registered }}</td>
        <td class="table-value-box">{{ formatDate(device.deviceAdded) }}</td>
        <td class="table-value-box">{{ deviceRemoved(device) }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "DevicesTable",
  data() {
    return {
      sortBy: "Latest record",
      sortDirection: "asc",
      tableHeadings: [
        // If adding a new table header, make sure to update the sorting logic
        // in the method `propertyName(heading)`
        { heading: "Serial" },
        { heading: "Latest record" },
        { heading: "Latest upload" },
        { heading: "Live trial" },
        { heading: "Registration" },
        { heading: "Registered from" },
        { heading: "Registered to" },
      ],
    };
  },
  computed: {
    devices() {
      return this.$store.state.devices;
    },
    sortedDevices() {
      var unsortedDevices = this.$store.state.devices;
      return unsortedDevices.sort((p1, p2) => {
        let modifier = 1;
        if (this.sortDirection === "desc") {
          modifier = -1;
        }
        var propertyName = this.propertyName(this.sortBy);
        if (p1[propertyName] < p2[propertyName]) {
          return -1 * modifier;
        }
        if (p1[propertyName] > p2[propertyName]) {
          return 1 * modifier;
        }
        return 0;
      });
    },
  },
  methods: {
    formatDate(date) {
      return date != "Not available"
        ? date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        : date;
    },
    deviceRemoved(device) {
      return device.deviceRemoved == null
        ? "-"
        : this.formatDate(device.deviceRemoved);
    },
    sort(s) {
      console.log(s);
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    propertyName(heading) {
      switch (heading) {
        case "Serial":
          return "serial";
        case "Latest record":
          return "lastRecorded";
        case "Latest upload":
          return "lastSeen";
        case "Live trial":
          return "status";
        case "Registration":
          return "registered";
        case "Registered from":
          return "deviceAdded";
        case "Registered to":
          return "deviceRemoved";
      }
    },
  },
};
</script>
<style scoped>

table {
  border-collapse: collapse;
  margin-bottom: 16px;
}

tr {
  border-bottom: 1pt solid rgb(154, 154, 154);
}

th {
  background: rgb(230, 227, 227);
  border-top: 1pt solid rgb(154, 154, 154);
  font-family: dyson-futura-medium;
}

th,
td {
  padding: 24px 16px;
  font-size: 14px;
  text-align: center;
}

.asc:after {
  content: "\25B2";
}

.desc:after {
  content: "\25BC";
}
</style>
