import router from "../router";
import store from "../store";
import axios from "axios";

export default class CognitoAuth {
  constructor() {
    (this.userSession = null),
    (this.appClient = process.env.VUE_APP_AWS_CLIENT_ID),
    (this.authAPI = axios.create({
      baseURL:
        process.env.VUE_APP_AWS_BASE_URL + process.env.VUE_APP_AWS_VERSION,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }));
    (this.broadcastChannel = new BroadcastChannel("758_admin_auth"))
  }

  async authenticate(userName, pass, cb) {
    let authReq = {
      username: userName,
      password: pass,
      app_client_id: this.appClient,
      pool_id: process.env.VUE_APP_AWS_POOL_ID,
    };

    console.log(`auth request ${authReq.pool_id}`);

    try {
      var response = await this.login(authReq);
      let challengeResp = response.data;
      this.userSession = challengeResp.Session;

      cb(null, challengeResp);
    } catch (error) {
      try {
        console.log(error.response);
        cb(error, null);
      } catch (TypeError) {
        console.log(error);
        cb("Network Error", null);
      }
    }
  }

  async authenticateOTP(username, otp, cb) {
    let authReq = {
      username: username,
      app_client_id: this.appClient,
      otp: otp,
      session: this.userSession,
    };

    try {
      var response = await this.loginOTP(authReq);
      let authResult = response.data;

      // Subscribe this tab to other login state changes
      this.broadcastChannel.onmessage = (event) => {
        if (event.data == "trigger_sign_out" && event.isTrusted == true) {
          router.push({ 
            name: 'Login Page', 
            query: { logout: true },
            replace: true
          })
        }
      }

      store.dispatch("login", {
        idToken: authResult.IdToken,
        accessToken: authResult.AccessToken,
      });

      cb(null, response);
    } catch (err) {
      console.log(err);
      cb(err, null);
    }
  }

  logoutUser() {
    // Sign out other tabs at the same time
    this.broadcastChannel.postMessage("trigger_sign_out")

    const logoutReq = {
      access_token: store.state.AccessToken,
    };
    this.authAPI
      .post("/auth/logout", null, { params: logoutReq })
      .then(store.dispatch("logout"))
      .catch((error) => {
        console.log(error);
      });
  }

  loginOTP(otpReq) {
    return this.authAPI.post("/auth/otplogin", otpReq);
  }

  login(loginReq) {
    return this.authAPI.post("/auth/login", loginReq);
  }
}
