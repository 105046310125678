<template>
  <!-- table tabs -->
  <div class="parent">
    <h2
      class="DD-Trials"
      :style="{ color: this.activeHStyling }"
      @click="activeTab = 'active'"
    >
      Active-trials
    </h2>
    <h2
      class="DD-Trials"
      :style="{ color: this.futureHStyling }"
      @click="activeTab = 'future'"
    >
      Future-trials
    </h2>
    <h2
      class="DD-Trials"
      :style="{ color: this.pastHStyling }"
      @click="activeTab = 'past'"
    >
      Past-trials
    </h2>
    <hr :class="navBarStyle" />
  </div>

  <!-- table data -->
  <DDTrialsSubsection :tableData="activeTab" :device="device" />
</template>
<script>
import DDTrialsSubsection from "./DDTrialsSubsection.vue";
export default {
  name: "DDTrials",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeTab: "active",
    };
  },
  components: {
    DDTrialsSubsection,
  },
  computed: {
    activeHStyling() {
      if (this.activeTab == "active") {
        return "black";
      } else {
        return "#999999";
      }
    },
    futureHStyling() {
      if (this.activeTab == "future") {
        return "black";
      } else {
        return "#999999";
      }
    },
    pastHStyling() {
      if (this.activeTab == "past") {
        return "black";
      } else {
        return "#999999";
      }
    },
    navBarStyle() {
      if (this.activeTab == "active") {
        return "nav-line-active";
      } else if (this.activeTab == "future") {
        return "nav-line-future";
      } else if (this.activeTab == "past") {
        return "nav-line-past";
      } else {
        console.log("invalid type in navBarStyle()");
        return null;
      }
    },
  },
};
</script>
<style scoped>
.parent {
  position: relative;
  margin-top: 5px;
}

.nav-line-active,
.nav-line-future,
.nav-line-past {
  position: absolute;
  border-top: 2px solid black;
  top: 15px;
}

.nav-line-active {
  width: 108px;
}
.nav-line-future {
  left: 129px;
  width: 114px;
}
.nav-line-past {
  left: 263px;
  width: 90px;
}

.DD-Trials {
  display: inline-block;
  padding: 0px 20px 0px 0px;
  margin-top: 0px;
  font-size: 20px;
}

h2.inactive {
  color: #999999;
}
</style>
