<template>
  <div :class="[cursor, 'page-body']">
    <h1>Edit Trial: {{ trial.trial_name }}</h1>
    <div>
      <p>
        <Part1Summary
          :name="trial.trial_name"
          :startDate="trial.start_date"
          :endDate="trial.end_date"
          :editPage="true"
          @custom-change="updateData"
        />
      </p>
      <!-- Part 2 Fields -->
      <h2>Available Devices:</h2>
      <div class="inline">
        <div class="side">
          <DeviceSelection
            :end_date="this.updatedEnd"
            :start_date="this.updatedStart"
            :chosen="trialDevices"
            @custom-change="updateData"
          />
        </div>
        <div class="side" id="errors">
          <p class="errors" v-for="error in errors" :key="error">
            {{ error }}
          </p>
        </div>
      </div>
      <button
        class="button secondary-button"
        @click="
          this.$router.replace({
            path: '/trial',
            query: { trialId: trial.trial_id },
          })
        "
      >
        Back
      </button>
      <button
        :class="['button', 'primary-button', cursor]"
        :disabled="!changed"
        @click="updateTrial"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import AxiosService from "@/services/AxiosService.js";
import Part1Summary from "@/components/CreateTrial/Part1Summary.vue";
import DeviceSelection from "@/components/CreateTrial/DeviceSelection.vue";

export default {
  name: "EditTrial",
  data() {
    return {
      cursor: "auto",
      newDevices: [],
      toUpdate: [],
      changed: false,
      updatedName: null,
      updatedEnd: null,
      updatedStart: null,
      trialEnd: null,
      trialStart: null,
      errors: [],
    };
  },
  computed: {
    trialDevices() {
      let deviceList = [];
      for (let serial of this.$route.query.trialDevices
        .slice(0, -1)
        .split(",")) {
        deviceList.push(serial);
      }
      return deviceList;
    },
    trial() {
      let trial = this.$store.state.trials[this.$route.query.trialName];
      return trial;
    },
  },
  created() {
    this.updatedName = this.$route.query.trialName;
    if (this.trial.end_date) {
      this.trialEnd = this.createDate(this.trial.end_date, false);
      this.updatedEnd = this.formatDate(this.trial.end_date);
    }
    this.trialStart = this.createDate(this.trial.start_date, false);
    this.updatedStart = this.formatDate(this.trial.start_date);
  },
  components: {
    DeviceSelection,
    Part1Summary,
  },
  methods: {
    updateData(event) {
      if (event instanceof Array) {
        this.newDevices = event;
      } else if (event instanceof Object) {
        if (event.type == "end_date") this.updatedEnd = event.val;
        else if (event.type == "start_date") this.updatedStart = event.val;
        else if (event.type == "name") this.updatedName = event.val;
      }
      this.changed = this.checkDifference();
    },
    checkDifference() {
      var changedData = [];

      if (
        this.newDevices.length != this.trialDevices.length ||
        !this.newDevices.every((device) => {
          return this.trialDevices.includes(device);
        })
      ) {
        changedData.push("device_list");
      }

      if (this.updatedName != this.trial.trial_name) changedData.push("name");

      const startDate = this.createDate(this.updatedStart, true);
      if (startDate.getTime() != this.trialStart.getTime())
        changedData.push("start_date");

      if (!this.trialEnd && this.updatedEnd) {
        changedData.push("end_date");
      } else if (this.updatedEnd) {
        const endDate = this.createDate(this.updatedEnd, true);
        if (endDate.getTime() != this.trialEnd.getTime())
          changedData.push("end_date");
      }

      this.toUpdate = changedData;

      return !(changedData.length == 0);
    },
    async updateTrial() {
      var trial = {};

      if (this.validateData()) {
        if (this.toUpdate.includes("end_date"))
          trial["end_date"] = this.updatedEnd;
        if (this.toUpdate.includes("start_date"))
          trial["start_date"] = this.updatedStart;
        if (this.toUpdate.includes("name"))
          trial["trial_name"] = this.updatedName;
        if (this.toUpdate.includes("device_list"))
          trial["device_list"] = this.newDevices.join(",");

        trial["trial_id"] = this.trial.trial_id;

        try {
          this.cursor = "loading";
          await AxiosService.updateTrial(trial);
          this.cursor = "auto";
          this.$store.dispatch("updateTrials"), this.$router.replace("/trials");
        } catch (err) {
          try {
            this.errors.push(err.response.data);
          } catch (err) {
            console.log(err);
          }
        }
      }
    },
    // For YYYYMMDDT00:00:00 format
    createDate(dateString, formatted) {
      var newDate;
      if (formatted) {
        newDate = new Date(
          parseInt(dateString.slice(0, 4)),
          parseInt(dateString.slice(5, 7)) - 1,
          parseInt(dateString.slice(8, 10))
        );
      } else {
        newDate = new Date(
          parseInt(dateString.slice(0, 4)),
          parseInt(dateString.slice(4, 6)) - 1,
          parseInt(dateString.slice(6, 8))
        );
      }
      return newDate;
    },
    formatDate(dateString) {
      return (
        dateString.slice(0, 4) +
        "-" +
        dateString.slice(4, 6) +
        "-" +
        dateString.slice(6, 8)
      );
    },
    validateData() {
      this.errors = [];
      if (
        this.toUpdate.includes("name") &&
        this.updatedName in this.$store.state.trials
      ) {
        this.errors.push("Test name must be unique.");
      }
      if (
        (this.toUpdate.includes("end_date") ||
          this.toUpdate.includes("start_date")) &&
        this.updatedEnd < this.updatedStart
      ) {
        this.errors.push("End date cannot be before start date.");
      }
      return this.errors.length == 0;
    },
  },
};
</script>

<style scoped>
#errors {
  position: relative;
}
button:disabled {
  background: #bbacc3;
}
div.inline {
  display: flex;
  width: 50%;
}
div.side {
  flex: 1;
}
p.errors {
  font-family: dyson-futura-book, Arial, sans-serif;
  color: red;
  text-align: center;
  margin: 0 0 0 25px;
  position: absolute;
  bottom: 0;
}
.auto {
  cursor: auto;
}
.loading {
  cursor: progress;
}
#trialId {
  margin-top: -15px;
}
div.page-body {
  margin-left: 60px;
  padding-top: 2%;
}
</style>
