<template>
  <!-- page body -->
  <div class="download page-body">
    <!-- Text and graphics section of webpage -->
    <div v-if="isLoading != downloadStatus['success']">
      <!-- Shown when download is processing -->
      <h1 class="download-element">
        Putting together {{ serialOrId }} records
      </h1>
      <br />
      <div v-if="isLoading != downloadStatus['error']">
        <p
          v-if="isLoading == downloadStatus['loading']"
          class="download-element"
        >
          Preparing your data for download
        </p>
        <p
          v-if="isLoading == downloadStatus['compressing']"
          class="download-element"
        >
          Compressing your data so it's smaller to download
        </p>
        <br />
        <Spinner />
        <br />
      </div>
      <div v-else>
        <p class="download-element error">Download Error</p>
        <br />
      </div>
    </div>
    <div v-if="isLoading == downloadStatus['success']">
      <h1 class="download-element">File download complete.</h1>
    </div>

    <!-- Cancel button -->
    <router-link :to="buttonLink">
      <button class="button secondary-button download-element">
        {{ buttonMsg }}
      </button>
    </router-link>
  </div>
</template>

<script>
import AxiosService from "@/services/AxiosService.js";
import Spinner from "../components/Spinner.vue";

export default {
  name: "DownloadView",
  data() {
    return {
      downloadStatus: {
        success: 0,
        loading: 1,
        compressing: 2,
        error: 3,
      },
      recordData: null,
      interval: null,
      invocId: null,
      error: null,
      errorMsg: null,
    };
  },
  components: {
    Spinner,
  },
  computed: {
    serialOrId() {
      return this.$route.query.serialOrId;
    },
    type() {
      return this.$route.query.type;
    },
    buttonLink() {
      // determines where the "cancel"/"back to" button redirects the user
      if (this.type == "devices") {
        return "/device?device=" + this.serialOrId;
      } else if (this.type == "trials") {
        return { path: "/trial", query: { trialId: this.serialOrId } };
      } else {
        console.log(
          "error - Invalid 'type' variable in buttonLink(), Download.vue"
        );
        return null;
      }
    },
    buttonMsg() {
      // allows the text in the button to change when teh download is loading vs completed
      if (this.isLoading == this.downloadStatus["loading"]) {
        return "Cancel";
      } else {
        return "Back to " + this.type;
      }
    },
    isLoading() {
      if (this.recordData) {
        if (this.recordData.Status == "SUCCEEDED") {
          clearInterval(this.interval);
          this.downloadFile();
          return this.downloadStatus["success"];
        } else if (this.recordData.Status == "INVOKED") {
          return this.downloadStatus["loading"];
        } else if (this.recordData.Status == "COMPRESSING") {
          return this.downloadStatus["compressing"];
        } else {
          console.log("Download Error: Status - ", this.recordData.Status);
          clearInterval(this.interval);
          return this.downloadStatus["error"];
        }
      }
      return this.downloadStatus["loading"];
    },
    navBarStyle() {
      //determines the correct css class for the navigation bar styling
      if (this.type == "devices") {
        return "nav-line-devices";
      } else if (this.type == "trials") {
        return "nav-line-trials";
      } else {
        console.log("error - invalid type in navBarStyle(), Download.vue ");
        return null;
      }
    },
  },
  methods: {
    // makes get request to API
    loadData() {
      let queryParams = {
        query_execution_id: this.invocId,
      };
      AxiosService.checkDownload(this.type, this.serialOrId, queryParams)
        .then((response) => {
          this.recordData = response.data;
          console.log("Returned from check download API: ", this.recordData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // stops component from calling LoadData() every 5 seconds (see mounted())
    leaving() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    //Creates a HTML <a> element and programmatically clicks it in order to download file.
    downloadFile() {
      const link = document.createElement("a");
      link.href = this.recordData.DownloadUrl;
      //adding a download attribute triggers a file being downloaded.
      link.download = "DysonAQData";
      link.click();
      //clean up
      URL.revokeObjectURL(link.href);
    },
  },
  // Makes a post request to API triggering backend processing
  created() {
    AxiosService.requestDownload(this.type, this.serialOrId)
      .then((response) => {
        this.invocId = response.data.ID;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  //sets up calling LoadData() every 2 seconds
  // see: https://www.w3schools.com/jsref/met_win_setinterval.asp
  mounted() {
    this.interval = setInterval(
      function () {
        this.loadData();
      }.bind(this),
      2000
    );
  },
  // prevents continuation of interval api calls if the user has left the page
  beforeRouteLeave(routeTo, routeFrom, next) {
    this.leaving();
    next();
  },
};
</script>
<style scoped>
.nav-line-devices,
.nav-line-trials {
  border-top: 2px solid #999999;
}
.download {
  text-align: center;
  padding-top: 5%;
}
#spinner {
  height: 75px;
}
.download-element {
  margin-bottom: 20px;
  display: inline-block;
}
p.error {
  font-family: dyson-futura-medium, Arial, sans-serif;
  color: red;
  text-align: center;
}
</style>
