<template>
  <div class="btn-container">
    <img
      class="edit-button"
      src="../assets/edit.svg"
      alt="Edit Icon"
      style="width: 42px; height: 42px"
    />
    <p>Edit</p>
  </div>
</template>

<script>
export default {
  name: "EditBtn",
};
</script>

<style scoped>
.btn-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  margin: -20px 0px 0px;
}
</style>
