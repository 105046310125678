<template>
  <!-- page body -->
  <div class="trials page-body">
    <h1>Delete trial: {{ trialName }}</h1>
    <p>
      Are you sure you want to delete this trial? Data recorded by your devices
      will still be accessible.
    </p>
    <div class="buttons">
      <button @click="$router.go(-1)" class="button secondary-button">
        Cancel
      </button>
      <button @click="deleteTrial()" class="button primary-button">
        Delete trial
      </button>
    </div>
  </div>
</template>

<script>
import AxiosService from "@/services/AxiosService.js";
export default {
  name: "DeleteTrial",
  computed: {
    trialName() {
      return this.$route.query.trialName;
    },
    trialId() {
      return this.$route.query.trialId;
    },
  },
  methods: {
    deleteTrial() {
      AxiosService.deleteTrial(this.trialId)
        .then(
          this.$store.dispatch("updateTrials"),
          this.$router.replace({ path: "/trials" })
        )
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.buttons {
  margin-top: 20px;
}
.primary-button {
  margin-left: 15px;
}
div.trials {
  padding-top: 1%;
}
</style>
