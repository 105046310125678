<template>
  <!-- table heading -->
  <div class="table-heading table-row">
    <hr class="solid" />
    <div
      v-for="tableHeading in tableHeadings"
      v-bind:key="tableHeading.heading"
      class="table-value-box"
    >
      {{ tableHeading.heading }}
    </div>
    <hr class="solid" />
  </div>
  <!-- table rows -->
  <div v-for="device in devices" v-bind:key="device.serial" class="table-row">
    <div class="table-value-box">
      <router-link :to="'/device?device=' + device.serial">{{
        device.serial
      }}</router-link>
    </div>
    <div class="table-value-box">{{ formatDate(device.lastSeen) }}</div>
    <div class="table-value-box">{{ formatDate(device.lastRecorded) }}</div>
    <hr class="solid" />
  </div>
</template>

<script>
export default {
  name: "TrialDevicesTable",
  props: {
    devices: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeadings: [
        { heading: "Serial" },
        { heading: "Lastest upload" },
        { heading: "Lastest record" },
      ],
    };
  },
  methods: {
    formatDate(date) {
      if (isNaN(parseInt(date.slice(0, 1)))) {
        return "Not available";
      } else {
        return (
          date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4)
        );
      }
    },
  },
};
</script>

<style scoped>
div.table-row {
  width: 384px;
}

/* heading styling */
div.table-heading {
  background: rgb(230, 227, 227);
  font-family: dyson-futura-medium;
}

/* row spacing and layout */
div.table-value-box {
  display: inline-block;
  padding: 10px 0;
  width: 120px;
  font-size: 14px;
  text-align: center;
}
</style>
