import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import AxiosService from "@/services/AxiosService.js";

const store = createStore({
  state() {
    return {
      IdToken: null,
      AccessToken: null,
      devices: null,
      trials: null,
    };
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { secure: true }),
        removeItem: (key) => Cookies.remove(key),
      },
      paths: ["IdToken", "AccessToken"],
    }),
  ],
  mutations: {
    setIdToken(state, token) {
      state.IdToken = token;
    },
    setAccessToken(state, token) {
      state.AccessToken = token;
    },
    setDevices(state, deviceList) {
      for (let device of deviceList) {
        device["registered"] = device.deviceRemoved
          ? "Unregistered"
          : "Registered";

        // Flatten the data structure so we don't need to deal with unnecessarily nested objects
        if (device.deviceAdded != null) {
          device["deviceAdded"] = device.deviceAdded.S;
        }
        if (device.deviceRemoved != null) {
          device["deviceRemoved"] = device.deviceRemoved.S;
        }
      }
      state.devices = deviceList;
    },
    setTrials(state, trialList) {
      let trialDict = {};
      if (trialList.length > 0) {
        for (let trial of trialList) {
          trialDict[trial.trial_name] = trial;
        }
      } else {
        trialDict = null;
      }
      state.trials = trialDict;
    },
  },
  actions: {
    async login(context, payload) {
      context.commit("setIdToken", payload.idToken);
      context.commit("setAccessToken", payload.accessToken);
      try {
        var deviceResp = await AxiosService.getDevices();
        context.commit("setDevices", deviceResp.data.devices);
        var trialsResp = await AxiosService.getTrials();
        context.commit("setTrials", trialsResp.data);
      } catch (error) {
        console.log(error);
      }
    },
    async updateTrials(context) {
      try {
        let resp = await AxiosService.getTrials();
        context.commit("setTrials", resp.data);
      } catch (error) {
        console.log(error);
      }
    },
    async updateDevices(context) {
      try {
        let resp = await AxiosService.getDevices();
        context.commit("setDevices", resp.data.devices);
      } catch (error) {
        console.log(error);
      }
    },
    logout(context) {
      context.commit("setAccessToken", null);
      context.commit("setIdToken", null);
    },
  },
  getters: {},
});

export default store;
