<template>
  <div>
    <div id="nav">
      <div class="navLink">
        <router-link to="/devices">Devices</router-link>
      </div>
      <div class="navLink"><router-link to="/trials">Trials</router-link></div>
      <div class="navLink">
        <router-link
          :to="{ name: 'Login Page', query: { logout: true } }"
          replace
          >Logout</router-link
        >
      </div>
    </div>
    <router-view />
    <!-- nav bar undreline  -->
    <hr class="nav-line-trials" />
    <!-- page body -->
    <div class="page-body">
      <h1>Trials</h1>
      <p v-if="this.$store.state.trials != null">
        A trial is a subset of your devices' data created through filtering by
        dates and devices. The table below lists all your trials on your device
        data you have created.
      </p>
      <p v-else>
        The device page lists all trials you have created. A tial is a filter of
        the data recorded by your devices which you can download. Press the
        create a trial button to create a trial.
      </p>

      <div v-if="this.$store.state.trials != null">
        <TrialsTable />
      </div>
      <p>
        <router-link to="/CreateTrial">
          <LoadingButton text="Create trial" :isLoading="false" />
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import TrialsTable from "../components/TrialsTable.vue";
import LoadingButton from "../components/LoadingButton.vue";

export default {
  name: "TrialsView",
  components: {
    TrialsTable,
    LoadingButton,
  },
};
</script>

<style scoped>
.nav-line-trials {
  border-top: 2px solid #000000;
}
</style>
