<template>
  <div>
    <PopUp
      :showProp="popUp"
      :attempt="attempt"
      :isLoading="isOTPLoading"
      type="otp"
      @custom-change="otpLogin"
    />
    <form id="form_login" @submit.prevent="passwordLogin">
      <div class="center">
        <img class="logo" src="./../../public/Dyson_logo.png" />
      </div>
      <h1>AQ</h1>
      <h2>Admin portal</h2>
      <div class="center">
        <div id="pad">
          <input
            class="username"
            type="text"
            placeholder="Username"
            v-model="username"
            required
          />
        </div>
      </div>
      <div class="center">
        <div id="pad">
          <input
            class="pass"
            :type="passwordType"
            placeholder="Password"
            v-model="pass"
            required
          />
          <div class="eye">
            <button type="button" class="pass" @click="togglePassword()">
              <img
                class="eye"
                v-if="!showPassword"
                src="./../../public/view.png"
              />
              <img class="eye" v-else src="./../../public/hidden.png" />
            </button>
          </div>
        </div>
      </div>
      <div class="text">
        <p class="error" v-if="error != null">
          {{ error }} <br />
          {{ blockedMsg }}
        </p>
        <p class="policy">
          For more information about how we handle your personal information,
          please read our Privacy Policy.
        </p>
      </div>
      <div class="center">
        <LoadingButton :isLoading="isLoading" text="Sign in" />
        <br />
      </div>
    </form>
  </div>
</template>

<script>
import LoadingButton from "../components/LoadingButton.vue";
import PopUp from "../components/PopUp.vue";
import CognitoAuth from "../services/cognito";
import store from "../store";

export default {
  name: "LoginView",
  props: {
    logout: {
      type: String,
      required: false,
      default: "false",
    },
  },
  components: {
    LoadingButton,
    PopUp,
  },
  created() {
    if (this.logout == "true" && store.state.AccessToken != null) {
      this.authenticator.logoutUser();
    }
  },
  data() {
    return {
      username: "",
      pass: "",
      otp: "",
      attempt: 1,
      error: null,
      blockedMsg: null,
      authenticator: new CognitoAuth(),
      showPassword: false,
      passwordType: "password",
      isLoading: false,
      isOTPLoading: false,
      popUp: false,
    };
  },
  methods: {
    async passwordLogin() {
      this.isLoading = true;
      await this.authenticator.authenticate(this.username, this.pass, (err) => {
        this.isLoading = false;
        if (err === null) {
          this.popUp = true;
        } else if (err === "Network Error") {
          this.error = "Network error";
          this.blockedMsg = "";
        } else if (err.response.status === 401) {
          this.error = "Invalid username/password";
          this.blockedMsg = "";
        } else if (err.response.status === 402) {
          this.error = "5 unsuccessful login attempts.";
          this.blockedMsg =
            "Your account has been temporarily suspended for 30 minutes";
        } else {
          this.error = "Unknown error, please get in touch with Dyson.";
        }
      });
    },
    async otpLogin(event) {
      this.isOTPLoading = true;
      await this.authenticator.authenticateOTP(this.username, event, (err) => {
        this.isOTPLoading = false;
        if (err === null) {
          this.popUp = false;
          this.$router.replace("/devices");
        } else if (err.response.status === 401) {
          this.attempt += 1;
        } else if (err.response.status === 402) {
          this.popUp = false;
          this.error = "Too many failed 2FA attempts.";
        }
      });
    },
    togglePassword() {
      if (this.showPassword === false) {
        this.showPassword = true;
        this.passwordType = "text";
      } else {
        this.showPassword = false;
        this.passwordType = "password";
      }
    },
  },
};
</script>

<style scoped>
#pad {
  margin: 15px 0px 0px 0px;
  border-style: solid;
  border-width: thin;
  border-color: grey;
  width: 350px;
  height: 70px;
}

h1 {
  text-align: center;
  margin: -30px 0px 0px 0px;
}

h2 {
  font-size: 18px;
  opacity: 0.5;
  text-align: center;
  margin: 15px 0px 0px 0px;
  font-family: dyson-futura-book;
}

.center input {
  font-size: 16px;
  font-family: dyson-futura-book;
  text-align: left;
  height: 40px;
  display: inline-block;
  border: none;
  padding: 0px 0px 0px 15px;
}

.username {
  width: 350;
}

input.pass {
  width: 295px;
}

*:focus {
  outline: none;
}

div.eye {
  position: relative;
  top: 7px;
  width: 35px;
  display: inline-block;
  border: none;
}

button.pass {
  background-color: white;
  bottom: 0;
  border: none;
}

div.center {
  margin: 0 auto;
  place-content: center;
}

div.text {
  width: 350px;
  place-content: left;
  padding: 5px 0px 15px 0px;
}

p.error {
  font-family: dyson-futura-medium, Arial, sans-serif;
  color: red;
  text-align: center;
}

p {
  font-size: 13px;
  opacity: 0.5;
}

img.eye {
  background-color: rgb(255, 255, 255);
  border: none;
  width: 24px;
  height: 24px;
}

img.logo {
  background-color: rgb(255, 255, 255);
  border: none;
  width: 120px;
}

#form_login {
  margin: auto;
  width: 50%;
  padding-top: 7%;
  display: grid;
  place-content: center;
}
</style>
