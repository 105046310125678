import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Devices from "../views/Devices.vue";
import Trials from "../views/Trials.vue";
import DeviceDetail from "../views/DeviceDetail.vue";
import TrialDetail from "../views/TrialDetail.vue";
import Download from "../views/Download.vue";
import CreateTrial from "../views/CreateTrial.vue";
import DeleteTrial from "@/views/DeleteTrial.vue";
import EditTrial from "../views/EditTrial.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Login Page",
    component: Login,
    props: route => ({ logout: route.query.logout })
  },
  {
    path: "/devices",
    name: "Devices",
    component: Devices,
    beforeEnter: () => {
      store.dispatch("updateDevices");
      return true;
    },
  },
  {
    path: "/trials",
    name: "Trials",
    component: Trials,
    beforeEnter: () => {
      store.dispatch("updateTrials");
      return true;
    },
  },
  {
    path: "/device",
    name: "DeviceDetail",
    component: DeviceDetail,
  },
  {
    path: "/trial",
    name: "TrialDetail",
    component: TrialDetail,
  },
  {
    path: "/download",
    name: "Download",
    component: Download,
  },
  {
    path: "/CreateTrial",
    name: "CreateTrial",
    component: CreateTrial,
  },
  {
    path: "/deleteTrial",
    name: "DeleteTrial",
    component: DeleteTrial,
  },
  {
    path: "/editTrial",
    name: "EditTrial",
    component: EditTrial,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
