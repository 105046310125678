<template>
  <!-- table heading -->
  <div class="table-heading table-row">
    <hr class="solid" />
    <div
      v-for="tableHeading in tableHeadings"
      v-bind:key="tableHeading.heading"
      class="table-value-box"
    >
      {{ tableHeading.heading }}
    </div>
    <hr class="solid" />
  </div>
  <!-- table rows -->
  <div
    v-for="trial in trials"
    v-bind:key="trial.trial_name.S"
    class="table-row"
  >
    <div class="table-value-box">
      <router-link :to="{ path: '/trial', query: { trialId: trial.trial_id.S, trialName: trial.trial_name.S }, }">{{
        trial.trial_name.S
      }}</router-link>
    </div>
    <div class="table-value-box">{{ formatDate(trial.start_date.S) }}</div>
    <div class="table-value-box">
      {{ trial.end_date ? formatDate(trial.end_date.S) : "To be decided" }}
    </div>
    <hr class="solid" />
  </div>
  <!-- {{device.activeTrials}} -->
</template>
<script>
export default {
  name: "DDTrialsSubsection",
  props: {
    tableData: {
      type: String,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeadings: [
        { heading: "Name" },
        { heading: "Start date" },
        { heading: "End date" },
      ],
    };
  },
  computed: {
    trials() {
      if (!this.device) return [];
      var selectedTrials;
      switch (this.tableData) {
        case "active":
          selectedTrials = this.device.activeTrials;
          break;
        case "future":
          selectedTrials = this.device.futureTrials;
          break;
        case "past":
          selectedTrials = this.device.previousTrials;
          break;
        default:
          selectedTrials = [];
      }
      return selectedTrials;
    },
  },
  methods: {
    formatDate(date) {
      return date.slice(6, 8) + "/" + date.slice(4, 6) + "/" + date.slice(0, 4);
    },
  },
};
</script>
<style scoped>
div.table-row {
  width: 400px;
}

/* headingstyling */
div.table-heading {
  background: rgb(230, 227, 227);
  font-family: dyson-futura-medium;
}

/* row spacing and layout */
div.table-value-box {
  display: inline-block;
  padding: 10px 0;
  width: 120px;
  font-size: 14px;
  text-align: center;
}
</style>
