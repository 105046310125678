<template>
  <div>
    <div id="nav">
      <div class="navLink"><router-link to="/devices">Devices</router-link></div>
      <div class="navLink"><router-link to="/trials">Trials</router-link></div>
      <div class="navLink">
        <router-link
          :to="{ name: 'Login Page', query: { logout: true } }"
          replace
          >Logout</router-link
        >
      </div>
    </div>
    <router-view />
    <!-- nav bar underline -->
    <hr class="nav-line-devices" />
    <!-- page body styling -->
    <div class="devices page-body">
      <h1>Devices</h1>
      
      <p v-if="this.$store.state.devices != null">
        The table below lists all devices that have been registered on this
        account.
      </p>
      
      <div v-if="this.$store.state.devices != null">
        <DevicesTable />
      </div>
      
      <p v-else>
        The device page lists all devices that have been registered on this
        account. You have not yet registered any devices. Use the Dyson AQ mobile
        admin app to register a device.
      </p>
      
      <div>
        <LoadingButton :isLoading="isLoading" text="Refresh" :onClick="refresh" />
        <LoadingButton text="Upload Public Key" :onClick="uploadPublicKeyClicked" />
      </div>

      <PublicKeyPopUp :show="showPublicKeyPopUp" @custom-change="closePublicKeyPopUp" />
      
    </div>
  </div>
</template>

<script>
import DevicesTable from "../components/DevicesTable.vue";
import LoadingButton from "../components/LoadingButton.vue";
import PublicKeyPopUp from "../components/PublicKeyPopUp.vue";
import store from "../store/index.js";

export default {
  name: "DevicesView",
  components: {
    DevicesTable,
    LoadingButton,
    PublicKeyPopUp,
  },
  data() {
    return {
      isLoading: false,
      showPublicKeyPopUp: false,
      ispubkeyLoading: false,
    };
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      await store.dispatch("updateDevices");
      this.isLoading = false;
    },
    uploadPublicKeyClicked() {
      this.showPublicKeyPopUp = true;
    },
    closePublicKeyPopUp() {
      this.showPublicKeyPopUp = false;
    }
  },
};
</script>

<style scoped>
.nav-line-devices {
  border-top: 2px solid rgb(0, 0, 0);
}
</style>
